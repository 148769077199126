import { NextSeo } from 'next-seo';

import { CloudinaryImage } from 'components/common/CloudinaryImage';

export const NotFoundTestIds = {
  container: 'not-found-container'
};

export const NotFound = () => (
  <>
    <NextSeo noindex />
    <div
      className='container main-content d-flex flex-column justify-content-center align-items-center'
      style={{ height: '75vh !important' }}
      data-testid={NotFoundTestIds.container}
    >
      <span className='text-primary bradford-medium'>Oooops...</span>
      <span className='mt-3 text-primary riforma-regular fs-1'>We can&apos;t find the page</span>
      <span className='text-primary riforma-regular fs-1'>you&apos;re looking for</span>
      <div
        className='m-5'
        style={{
          width: '30px',
          height: '30px'
        }}
      >
        <CloudinaryImage responsive sizes='40vw' alt='Bezel logo' src='logo.png' />
      </div>
    </div>
  </>
);
